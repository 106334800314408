import axios from 'axios';
import { logger } from '@nsoft/chameleon-sdk/src/utility';
import config from '../../config';

const http = axios.create({
  baseURL: config.pubSubEndpoint,
  headers: { 'Content-Type': 'application/json' },
});

export default {
  setState(settings) {
    return http.post('/setState', settings).catch((error) => {
      logger.warn('Failed to set state', error);
    });
  },
  publish(settings) {
    const payload = settings;
    payload.type = 'stateChanged';

    return http.post('/publish', payload).catch((error) => {
      logger.warn('Failed to publish event', error);
    });
  },
};
