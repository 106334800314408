<template>
  <div id="app"
       class="ticket-preview-base">
    <router-view />
  </div>
</template>

<script>
import { eventBus } from '@/utility';
import { localPubSubApi } from '@/api';
import config from '../config';

const localPubSubSettings = {
  channel: 'TicketPreview',
  data: {
    appActive: true,
  },
};

export default {
  name: 'App',
  created() {
    if (!config.debug) {
      document.addEventListener('keydown', (e) => {
        e.stopPropagation();
        e.preventDefault();
      });
    }

    eventBus.$on('TicketChecked', (data) => {
      this.$store.dispatch('setCheckedTicket', data);

      if (this.$route.path !== '/ticket-check') {
        this.$router.push('/ticket-check').catch(() => {});
      }
    });
  },
  mounted() {
    // make sure this is set to true
    localPubSubSettings.data.appActive = true;

    // set initial state through local pubsub
    localPubSubApi.setState(localPubSubSettings).then(() => {
      localPubSubApi.publish(localPubSubSettings);
    });

    // disable contextmenu
    if (!config.debug) {
      document.addEventListener('contextmenu', (e) => {
        e.preventDefault();
      }, false);
    }
  },
  destroyed() {
    localPubSubSettings.data.appActive = false;
    localPubSubApi.publish(localPubSubSettings);
  },
};
</script>

<style lang="scss">
.ticket-preview-base {
  position: relative;
  min-height: 100vh;
  max-width: 100%;
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
}
</style>
