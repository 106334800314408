import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

export default {
  init(router) {
    Sentry.init({
      Vue,
      dsn: 'https://4c79d30b87f4454ba9ae9063a7dd6131@o73276.ingest.sentry.io/4504631972003840',
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', /^\//],
        }),
      ],
      tracesSampleRate: 0.0,
    });
  },
};
