import {
  assign,
  isEmpty,
  isUndefined,
} from 'lodash';
import axios from 'axios';

const deploymentMap = {
  'nsoft-ticket-preview.pibet.net': 'expo',
  'localhost:8080': 'expo',
  'localhost:8081': 'expo',
  'balkanbet-ticket-preview.pibet.net': 'balkanbet',
  'isi-paray-tick-preview.pibet.net': 'isiparyaj',
};

const parseFromQuerystring = (query) => {
  const parsed = {};
  query.substring(1).replace(/([^=&]+)=([^&]*)/g, (m, key, value) => {
    parsed[window.decodeURIComponent(key)] = window.decodeURIComponent(value);
  });

  return parsed;
};

const queryConfig = parseFromQuerystring(window.location.search);
const config = {
  debug: queryConfig.debugMode === 'true',
  pubSubEndpoint: process.env.VUE_APP_LOCAL_PUB_SUB_ENDPOINT,
  subscriptionEndpoint: process.env.VUE_APP_SUBSCRIPTION_ENDPOINT,
  subscriptionChannels: process.env.VUE_APP_SUBSCRIPTION_CHANNELS.split(','),
  subscriptionEvents: process.env.VUE_APP_SUBSCRIPTION_EVENTS.split(','),
};

const isMarketingEnabled = (marketingUrl) => {
  if (!marketingUrl || isEmpty(marketingUrl)) return false;

  return isUndefined(queryConfig.marketingDisabled) ? true : queryConfig.marketingDisabled !== 'true';
};

export function loadConfig() {
  // query param deployment overrides host - make QA and debugging eqasier
  const deployment = queryConfig.deployment || deploymentMap[window.document.location.host];

  return axios.get(`${process.env.VUE_APP_GRAVITY_API_ENDPOINT}apps/${deployment}_ticket_preview/settings`)
    .then(({ data }) => {
      const deploymentConfig = data.appSettings.config || {};
      assign(config, deploymentConfig);

      config.idleTimeout = config.idleTimeout || process.env.VUE_APP_DEFAULT_IDLE_TIMEOUT;
      config.layout = queryConfig.layout || deploymentConfig.layout || process.env.VUE_APP_DEFAULT_LAYOUT;
      config.betsLayout = queryConfig.betsLayout || deploymentConfig.betsLayout || process.env.VUE_APP_DEFAULT_BETS_LAYOUT;
      config.marketingEnabled = isMarketingEnabled(deploymentConfig.marketingUrl);
    });
}

export default config;
