import io from 'socket.io-client';
import { each } from 'lodash';
import { logger } from '@nsoft/chameleon-sdk/src/utility';
import config from '../../config';
import eventBus from './eventBus';

const eventMapper = {
  // messages about ticket updates
  '7ShopBetslip': 'TicketChanged',
  // messages for managing ticket preview visibility
  '7ShopTicketPreview': 'ModeChanged',
  // messages regarding shop user change
  '7ShopUser': 'UserChanged',
  // messages for ticket check action
  '7ShopTickets': 'TicketChecked',
  // messages for displaying customer card data
  '7ShopCustomerCard': 'CustomerCard',
};

class SocketClient {
  constructor() {
    this.instance = null;
    this.endpoint = config.subscriptionEndpoint;
    this.channels = config.subscriptionChannels;
    this.events = config.subscriptionEvents;

    this.initializeConnection();
  }

  initializeConnection() {
    this.instance = io(this.endpoint);
    this.instance.on('connect', () => {
      this.subscribeChannels();
      this.setListeners();
    });
  }

  setListeners() {
    each(this.events, (event) => {
      this.instance.on(event, (message) => {
        const mappedEvent = eventMapper[event];
        if (!mappedEvent) {
          logger.warn('Unmapped event message received', { event, message });
          return;
        }

        eventBus.$emit(mappedEvent, message.data);
      });
    });
  }

  subscribeChannels() {
    each(this.channels, (channel) => {
      this.instance.emit('subscribe', {
        channel,
      });
    });
  }
}

export default SocketClient;
