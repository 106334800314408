import Vue from 'vue';
import { SocketClient, Sentry } from './utility';
import App from './App';
import router from './router';
import store from './store';
import { loadConfig } from '../config';
import './styles/main.scss';

Vue.config.productionTip = false;
Vue.prototype.$socketClient = new SocketClient();

Sentry.init(router);

loadConfig().then(() => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
});
